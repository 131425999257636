import React from "react";
import { Helmet } from "react-helmet";
const Layout = ({ children, title, description, keywords, author }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
    </div>
  );
};

// Layout.defaultProps = {
//   title: "Netligent Technologies",
//   description: "IT Training Institute",
//   keywords:
//     "IT training institute in bhopal, Best Hacking Institute, It related courses, Computer institute bhopal, computer classes bhopal, Python coaching in bhopal, Cyber Security Courses, IT Security Courses, AWS Course, Devops Course, Web Development Courses,",
//   author: "Netligent",
// };

export default Layout;
