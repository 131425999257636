import React from "react";
import "./home.css";
import { UilArrowRight } from "@iconscout/react-unicons";

export default function Services() {
  return (
    <>
      {/* <!-- ======= Our Services Section ======= --> */}
      <section className="bg-[#eeeeee] p-1 mt-16">
      <div className='flex flex-col justify-center items-center  '>
        <div className="grid grid-cols-1   mt-8 lg:mx-32 sm:mx-16 mx-8  w-full">
          <div className="p-3 text-center   ">
            <h3 className=" bx1 sm:text-2xl text-xl uppercase font-semibold ">
             Our Services
            </h3>
          </div>
        </div>
        <div className="lg:border-2 border-[#c43238] w-[10%] justify-center animate-bounce ">
          <div className=" absolute hideen lg:block"></div>
        </div>
        </div>
        <div
          className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 mx-4 sm:mx-16 gap-8 mt-8 "
          id="ClassroomTraining"
        >
          <div className=" bg-white  rounded-xl p-7 text-start block items-start justify-start bx2">
            <img
              src="classrom1.png"
              className=" h-14 w-16 mt-5 "
              alt="loading"
            ></img>
            <p className="  text-lg font-semibold  mt-5 text-[#c43238] ">
              Classroom Training
            </p>
            <hr className=" border-gray-300 mt-2"></hr>
            <p className="mt-5">
              {" "}
              Netligent caters collaborative,digitalized and hyper-personalized
              classroom training modules available for students from all around
              the world...
            </p>
            <p className="mt-5 tracking-wide text-[#1A2942]">Read More</p>
            <span className=" text-[#1A2942] ml-20 -mt-[22px]   flex space-x-1">
              <UilArrowRight />{" "}
            </span>
          </div>

          <div
            className=" bg-white rounded-xl  p-7 text-start block items-start justify-start bx2"
            id="InstructorLedOnline"
          >
            <img src="ins3.jpg" className=" h-14 w-16 mt-5" alt="loading"></img>
            <p className=" text-lg font-semibold  mt-5 text-[#c43238] ">
              Instructor Led Online
            </p>
            <hr className=" border-gray-300 mt-2"></hr>
            <p className="mt-5">
              {" "}
              Netligent Led Online training will allow you to certify your
              professional development need from home. The exclusive training...
            </p>
            <p className="mt-5 tracking-wide text-[#1A2942]">Read More</p>
            <span className=" text-[#1A2942] ml-20 -mt-[22px]   flex space-x-1">
              <UilArrowRight />{" "}
            </span>
          </div>

          <div
            className=" bg-white rounded-xl p-7 text-start  items-start justify-start hidden lg:block bx2"
            id="CorporateTraining"
          >
            <img
              src="corporate.png"
              className=" h-14 w-16 mt-5"
              alt="loading"
            ></img>
            <p className=" text-lg font-semibold  mt-5  text-[#c43238]">
              Corporate Training
            </p>
            <hr className=" border-gray-300 mt-2"></hr>
            <p className="mt-5">
              {" "}
              Rising competition needs an equal amount of growth. We provide the
              best boosting and engaging training to the employees...
            </p>
            <p className="mt-5 tracking-wide text-[#1A2942]">Read More</p>
            <span className=" text-[#1A2942] ml-20 -mt-[22px]   flex space-x-1">
              <UilArrowRight />{" "}
            </span>
          </div>
        </div>

        <div
          className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 mx-4 sm:mx-16 gap-8 mt-8 mb-24 bx3"
          id="1-on-1Training"
        >
          <div className=" bg-white  rounded-xl p-7 text-start block items-start justify-start">
            <img src="led.png" className=" h-14 w-16 mt-5 " alt="loading"></img>
            <p className="  text-lg font-semibold  mt-5 text-[#c43238]">
              1-on-1 Training
            </p>
            <hr className=" border-gray-300 mt-2"></hr>

            <p className="mt-5">
              {" "}
              Maximizing the focus and attention through 1 on 1 training with us
              will help you to improve with easy to modify course and schedule
              according to...
            </p>
            <p className="mt-5 tracking-wide text-[#1A2942]">Read More</p>
            <span className=" text-[#1A2942] ml-20 -mt-[22px]   flex space-x-1">
              <UilArrowRight />{" "}
            </span>
          </div>

          <div
            className=" bg-white rounded-xl  p-7 text-start block items-start justify-start bx3"
            id="SelfpacedTraining"
          >
            <img
              src="selfpaced.svg"
              className=" h-14 w-16 mt-5"
              alt="loading"
            ></img>
            <p className=" text-lg font-semibold  mt-5  text-[#c43238]">
              Selfpaced Training
            </p>
            <hr className=" border-gray-300 mt-2"></hr>
            <p className="mt-5">
              Balance your learning efficiency in your style, scale your own
              learning schedule according to your convenience and improve
              your...
            </p>
            <p className="mt-5 tracking-wide text-[#1A2942]">Read More</p>
            <span className=" text-[#1A2942] ml-20 -mt-[22px]   flex space-x-1">
              <UilArrowRight />{" "}
            </span>
          </div>

          <div
            className=" bg-white rounded-xl p-7 text-start  items-start justify-start hidden lg:block bx3"
            id="Campus"
          >
            <img
              src="campus1.png"
              className=" h-14 w-16 mt-5"
              alt="loading"
            ></img>
            <p className=" text-lg font-semibold  mt-5  text-[#c43238]">
              Campus Training
            </p>
            <hr className=" border-gray-300 mt-2"></hr>
            <p className="mt-5">
              {" "}
              Best cost effective training service by Netligent Technologies
              help you to develop new technology and methods to increase
              efficiency and...
            </p>
            <p className="mt-5 tracking-wide text-[#1A2942]">Read More</p>
            <span className=" text-[#1A2942] ml-20 -mt-[22px]   flex space-x-1">
              <UilArrowRight />{" "}
            </span>
          </div>
        </div>
      </section>

      {/* Services or 6th section end  */}
    </>
  );
}
