import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
function RecentPlacement() {
  const students = [
    {
      name: "Abhinav Pavithran",
      position: 'DEVOPS Engineer',
      company: "Flourishers Edge",
      image: "Abhinav.jpeg",
    },
    {
      name: "Shreya Jain",
      position: 'Python Developer',
      company: "Shroti Telecom",
      image: "Shreya.jpg",
    },
    {
      name: "Jaishankar Jaiswal",
      position:'FullStack Developer',
      company: "Cyntech IT Solution",
      image: "Jaishankar.jpeg",
    },
    {
      name: "Kuldeep Maurya",
      position:'FrontEnd Developer',
      company: "Flourishers Edge",
      image: "Kuldeep.jpg"
    },
    // Add more students as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className='w-full flex flex-col gap-5 md:gap-10 justify-center items-center'>
        <div className='flex flex-col justify-center items-center  '>
        <div className="grid grid-cols-1  mt-16 lg:mx-32 sm:mx-16 mx-8  w-full">
          <div className="p-3 text-center   ">
            <h3 className=" bx1 sm:text-2xl text-xl uppercase font-semibold ">
             Our Recent Placed Students
            </h3>
       
          </div>
        </div>
        <div className="lg:border-2 border-[#c43238] w-[20%] justify-center animate-bounce ">
          <div className=" absolute hideen lg:block"></div>
        </div>
        
        </div>


        <div className="w-[95%]  xl:w-[90%] ">
      <Slider {...settings} className=''>
        {students.map((student, index) => (

          <div key={index}
          className='flex flex-row justify-center items-center bg-gradient-to-l from-[#2e446c]  to-[#c43238]  rounded-xl'>
          <div className='flex w-full gap-5 md:gap-5   items-center px-5 py-5'>
          <div className='flex justify-center items-center h-20 w-20 md:h-24 md:w-24  bg-white  rounded-full '>
            <img src={student.image} alt={student.name} className=' object-contain rounded-full h-[70px] w-[70px] md:w-20 md:h-20'/>
          </div>

<div className='flex flex-col justify-start items-start  '>
  <h1 className='text-xl lg:text-2xl font-semibold text-white text-start'>{student.name}</h1>
  <h2 className='text-white mt-1'>{student.position}</h2>
  <p className='text-white -mt-1'>{student.company}</p>
</div>
          </div>
           
          </div>
        

        
        ))}
      </Slider>
    </div>
        
    </section>
  )
}

export default RecentPlacement

