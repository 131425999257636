import React  from "react";
import "./home.css";
import { Link } from "react-router-dom";

export default function Courses2() {
  return (
    <>
      {/* Course or third section start */}
      <section>
      <div className='flex flex-col justify-center items-center  '>
        <div className="grid grid-cols-1   mt-8 lg:mx-32 sm:mx-16 mx-8  w-full">
          <div className="p-3 text-center   ">
            <h3 className=" bx1 sm:text-2xl text-xl uppercase font-semibold ">
             Our Courses
            </h3>
          </div>
        </div>
        <div className="lg:border-2 border-[#c43238] w-[10%] justify-center animate-bounce ">
          <div className=" absolute hideen lg:block"></div>
        </div>
        </div>
        
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 mt-8 text-base  lg:gap-4 gap-8 lg:mx-16 md:mx-14 sm:mx-8 mx-4">
          <div className="box1 bx1">
            <div className="bg-[#c43238] pl-3  pt-6 flex justify-start  ">
              <img
                src="python.png "
                alt='loading...'
                className="h-12 w-12  rounded-full border-2 bg-white mt-4"
              ></img>
              <h2 className="text-start -mt-2  uppercase  text-[15px] p-3 text-white mb-3 tracking-wide ">
                Python <br /> Certification Training
              </h2>
            </div>

            {/* For overlay hover effect */}
            <div className="content-overlay hidden lg:block"></div>
            <div className="content-details fadeIn-left text-white hidden lg:block">
              <p className=" text-xl italic  text-center   ">Key Features</p>
              <div className=" text-white mt-1  text-start ">
                <p className="mt-8 italic">What will I learn?</p>
              </div>
              <ul className=" text-[13px] mt-2  list-disc ml-5 justify-center  ">
                <li className="text-center"> 130 Hours Of Learning</li>
                <li className="text-center">3 Hours Of Assignments</li>
                <li className=" text-center">100% Job Assignment</li>
              </ul>
              <Link to="/Python-Fullstack-Developer-Course" target="_parent">
                <div className="p-2  border uppercase text-xs font-bold mt-5 rounded-md cursor-pointer ">
                  <span>View Details</span>
                </div>{" "}
              </Link>
            </div>

            {/* For overlay hover effect end */}
            <div className=" p-5  ">
              <h2 className=" text-start font-semibold">
                Python Certification Training{" "}
              </h2>
              <img
                src="clock.png "
                className="h-6 w-6   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">3 Months</p>
              <img
                src="book.png "
                className="h-5 w-5   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">Offline/Hybrid</p>
              <Link to="/Python-Fullstack-Developer-Course" target="_parent">
                <div
                  className=" p-3 lg:hidden block   uppercase text-xs font-bold mt-5 
                             rounded-md cursor-pointer  hover:bg-black hover:text-white border border-black transition ease-in-out duration-500  "
                  id="spandiv"
                >
                  <span>View Details</span> <em></em>
                </div>{" "}
              </Link>
            </div>
          </div>
          <div className="box1 bx2">
            <div className="bg-[#1A2942] pl-3  pt-6 flex justify-start ">
              <img
                src="cs3.png "
                className="h-12 w-12  rounded-full border-2 bg-white mt-4"
                alt='loading...'
              ></img>
              <h2 className="text-start -mt-2  uppercase  text-[15px] p-3 text-white mb-3 tracking-widest ">
                Cyber Security <br /> Certification Training
              </h2>
            </div>

            {/* For overlay hover effect */}
            <div className="content-overlay hidden lg:block"></div>
            <div className="content-details fadeIn-left text-white hidden lg:block">
              <p className=" text-xl italic  text-center   ">Key Features</p>
              <div className=" text-white mt-1  text-start ">
                <p className="mt-8 italic">What will I learn?</p>
              </div>
              <ul className=" text-[13px] mt-2  list-disc ml-5 justify-center  ">
                <li className="text-center"> 130 Hours Of Learning</li>
                <li className="text-center">3 Hours Of Assignments</li>
                <li className=" text-center">100% Job Assignment</li>
              </ul>
              <Link to="/Cyber-Security-Expert-Course" target="_parent">
                <div className="p-2  border uppercase text-xs font-bold mt-5 rounded-md cursor-pointer ">
                  <span>View Details</span>
                </div>{" "}
              </Link>
            </div>

            {/* For overlay hover effect end */}
            <div className=" p-5  ">
              <h2 className=" text-start font-semibold ">
                Cyber Security Certification Training{" "}
              </h2>
              <img
                src="clock.png "
                className="h-6 w-6   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">8 Months</p>
              <img
                src="book.png "
                className="h-5 w-5   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">Offline/Hybrid</p>
              <Link to="/Cyber-Security-Expert-Course" target="_parent">
                <div
                  className=" p-3 lg:hidden block   uppercase text-xs font-bold mt-5 
                             rounded-md cursor-pointer  hover:bg-black hover:text-white border border-black transition ease-in-out duration-500  "
                  id="spandiv"
                >
                  <span>View Details</span> <em></em>
                </div>
              </Link>
            </div>
          </div>

          <div className="box1 bx3">
            <div className="bg-[#c43238] pl-3  pt-6 flex justify-start  ">
              <img
                src="redhat.png "
                className="h-12 w-12  rounded-full border-2 bg-white mt-4"
                alt='loading...'
              ></img>
              <h2 className="text-start -mt-2  uppercase  text-[15px] p-3 text-white mb-3 tracking-widest ">
                linux <br /> Certification Training
              </h2>
            </div>

            {/* For overlay hover effect */}
            <div className="content-overlay hidden lg:block"></div>
            <div className="content-details fadeIn-left text-white hidden lg:block">
              <p className=" text-xl italic  text-center   ">Key Features</p>
              <div className=" text-white mt-1  text-start ">
                <p className="mt-8 italic">What will I learn?</p>
              </div>
              <ul className=" text-[13px] mt-2  list-disc ml-5 justify-center  ">
                <li className="text-center"> 130 Hours Of Learning</li>
                <li className="text-center">3 Hours Of Assignments</li>
                <li className=" text-center">100% Job Assignment</li>
              </ul>
              <Link to="/Redhat-Expert-Course" target="_parent">
                <div className="p-2  border uppercase text-xs font-bold mt-5 rounded-md cursor-pointer ">
                  <span>View Details</span>
                </div>{" "}
              </Link>
            </div>

            {/* For overlay hover effect end */}
            <div className=" p-5  ">
              <h2 className=" text-start font-semibold">
                Linux Certification Training{" "}
              </h2>
              <img
                src="clock.png "
                className="h-6 w-6   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">2 Months</p>
              <img
                src="book.png "
                className="h-5 w-5   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">Offline/Hybrid</p>
              <Link to="/Redhat-Expert-Course" target="_parent">
                <div
                  className=" p-3 lg:hidden block   uppercase text-xs font-bold mt-5 
                             rounded-md cursor-pointer  hover:bg-black hover:text-white border border-black transition ease-in-out duration-500  "
                  id="spandiv"
                >
                  <span>View Details</span> <em></em>
                </div>
              </Link>
            </div>
          </div>
          <div className="box1 bx4">
            <div className="bg-[#1A2942] pl-3  pt-6 flex justify-start  ">
              <img
                src="javascript.png "
                className="h-12 w-12  rounded-full border-2 bg-white mt-4"
                alt='loading...'
              ></img>
              <h2 className="text-start -mt-2  uppercase  text-[15px] p-3 text-white mb-3 tracking-widest ">
                JavaScript
                <br /> Certification Training
              </h2>
            </div>

            {/* For overlay hover effect */}
            <div className="content-overlay hidden lg:block"></div>
            <div className="content-details fadeIn-left text-white hidden lg:block">
              <p className=" text-xl italic  text-center   ">Key Features</p>
              <div className=" text-white mt-1  text-start ">
                <p className="mt-8 italic">What will I learn?</p>
              </div>
              <ul className=" text-[13px] mt-2  list-disc ml-5 justify-center  ">
                <li className="text-center"> 130 Hours Of Learning</li>
                <li className="text-center">3 Hours Of Assignments</li>
                <li className=" text-center">100% Job Assignment</li>
              </ul>
              <Link to="/React-Developer-Course" taeget="_parent">
                <div className="p-2  border uppercase text-xs font-bold mt-5 rounded-md cursor-pointer ">
                  <span>View Details</span>
                </div>
              </Link>
            </div>

            {/* For overlay hover effect end */}
            <div className=" p-5  ">
              <h2 className=" text-start font-semibold">
                JavaScript Certification Training{" "}
              </h2>
              <img
                src="clock.png "
                className="h-6 w-6   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">5 Months</p>
              <img
                src="book.png "
                className="h-5 w-5   mt-3 "
                alt='loading...'
              ></img>
              <p className="text-start ml-9 -mt-6 ">Offline/Hybrid</p>
              <Link to="/React-Developer-Course" target="_parent">
                <div
                  className=" p-3 lg:hidden block   uppercase text-xs font-bold mt-5 
                             rounded-md cursor-pointer  hover:bg-black hover:text-white border border-black transition ease-in-out duration-500  "
                  id="spandiv"
                >
                  <span>View Details</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Link to="/Courses" target="_top">
          <button
            type="submit"
            className="relative box2  btn1  border  uppercase font-bold border-gradient-to-r from-[#c43238] to-[#1A2942]  mt-8 inline-flex items-center justify-start px-6 py-3 overflow-hidden transition-all bg-white rounded hover:bg-white group"
          >
            <span class="w-48 h-48 rounded rotate-[-40deg] bg-gradient-to-r from-[#c43238] to-[#1A2942] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
            <span class="relative w-full text-left text-black transition-colors duration-300 ease-in-out group-hover:text-white">
              Explore All Courses
            </span>
          </button>{" "}
        </Link>
      </section>
      {/* Course or third section end */}
    </>
  );
}
